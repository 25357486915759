import React, { useState } from "react";
import {
  Box,
  Select,
  Button,
  Switch,
  Text,
  Flex,
  Image,
} from "@chakra-ui/react";
import Header from "innovate/components/common/Header";
import SelectInput from "innovate/components/Inputs/SelectInput";
import info from "innovate/assets/images/info.svg";
import vectorback from "innovate/assets/images/vectorback.svg";

import { Option } from "innovate/components/Inputs/MultiSelectInput";

const GlobalView: React.FC = () => {
  const zone = ["South Africa", "Brazil"];
  const years = [2020, 2021, 2022, 2023];
  const aggregates = ["Volume", "Revenue", "Profit"];

  const createData = <T extends string | number>(
    items: T[]
  ): { value: T; label: string }[] =>
    items.map((item: T) => ({
      value: item,
      label: item.toString(),
    }));
  const zoneData = createData(zone);
  const yearData = createData(years);
  const aggregatedData = createData(aggregates);
  const [selectedZone, setSelectedZone] = useState<Option | null>(null);
  const [selectedMarket, setSelectedMarket] = useState<Option | null>(null);
  const [selectedYear, setSelectedYear] = useState<Option | null>(null);
  const [selectedAggregate, setSelectedAggregate] = useState<Option | null>(
    null
  );

  const handleFilter = (filterType: string, selectedOptions: Option | null) => {
    if (filterType === "zone") {
      setSelectedZone(selectedOptions);
    }

    if (filterType === "market") {
      setSelectedMarket(selectedOptions);
    }
    if (filterType === "year") {
      setSelectedYear(selectedOptions);
    }
    if (filterType === "aggregate") {
      setSelectedAggregate(selectedOptions);
    }
    // Handle other filter types if needed
  };

  console.log("wokring", zoneData);

  return (
    <>
      <Header />
      <Box
        position="relative"
        p={4}
        bg="#FFFFFF"
        m={10}
        borderRadius="md"
        boxShadow="sm"
        _before={{
          content: '""',
          position: "absolute",
          top: "15%", // Adjust top position as needed
          zIndex: -1,
          left: -2, // Offset to align with the main box
          bottom: "20%", // Adjust bottom position as needed
          height: "70%", // Adjust height if needed
          width: "20px", // Adjust width as needed
          backgroundColor: "#F1B800", // Yellow color
          borderRadius: "10px 0 0 10px", // Curved borders on top-left and bottom-left
        }}
      >
        <Flex align="center" gap={4} flexWrap="wrap" className="amol">
          {/* Zones Dropdown */}
          <Flex direction="column" gap={5}>
            <Flex align="center" gap={1}>
              <Text variant="custom">Zone</Text>
              <Image height="16px" src={info} opacity={0.5} />
            </Flex>
            <SelectInput
              name="globalInput"
              id="globalZoneInput"
              options={zoneData}
              customWidth="150px"
              isRequired
              value={selectedZone}
              onChange={(selectedOptions) =>
                handleFilter("zone", selectedOptions)
              }
            />
          </Flex>
          <Flex direction="column" gap={5}>
            <Flex align="center" gap={1}>
              <Text variant="custom">Markets</Text>
              <Image height="16px" src={info} opacity={0.5} />
            </Flex>
            {/* Markets Dropdown */}
            <SelectInput
              name="globalInput"
              id="globalMarketsInput"
              options={zoneData}
              customWidth="150px"
              isRequired
              value={selectedMarket}
              onChange={(selectedOptions) =>
                handleFilter("market", selectedOptions)
              }
            />
          </Flex>
          <Flex direction="column" gap={5}>
            <Flex align="center" gap={1}>
              <Text variant="custom">Years</Text>
              <Image height="16px" src={info} opacity={0.5} />
            </Flex>
            <Box>
              <SelectInput
                name="globalInput"
                id="globalYearsInput"
                options={yearData}
                customWidth="150px"
                isRequired
                value={selectedYear}
                onChange={(selectedOptions) =>
                  handleFilter("year", selectedOptions)
                }
              />
            </Box>
          </Flex>
          <Flex direction="column" gap={5}>
            <Flex align="center" gap={1}>
              <Text variant="custom">Aggregate</Text>
              <Image height="16px" src={info} opacity={0.5} />
            </Flex>
            <Box>
              <SelectInput
                name="globalInput"
                id="globalAggregateInput"
                options={aggregatedData}
                customWidth="150px"
                isRequired
                value={selectedAggregate}
                onChange={(selectedOptions) =>
                  handleFilter("aggregate", selectedOptions)
                }
              />
            </Box>
          </Flex>
          <Flex direction="column" gap={5}>
            <Flex align="center" gap={1}>
              <Text variant="custom">Show</Text>
              <Image height="16px" src={info} opacity={0.5} />
            </Flex>
            {/* Toggle for Percentage */}
            <Flex
              align="center"
              gap={2}
              p={2}
              border="1px solid #1414141f"
              borderRadius="8px"
            >
              <Text variant="custom">Absolute</Text>
              <Switch size="sm" colorScheme="teal" />
              <Text variant="custom">Percentage(%)</Text>
            </Flex>
          </Flex>

          {/* Buttons */}
          <Flex direction="column" flex="1" gap={2}>
            <Button
              variant="green"
              border="none"
              color="white"
              py={4}
              size="sm"
            >
              <Image src={vectorback} m={1} />
              <Text color="#FFFFFF">Export to Excel</Text>
            </Button>
            <Flex gap={4}>
              <Button variant="outline" color="white" size="sm" py={4} flex="1">
                Reset
              </Button>
              <Button variant="outline" color="white" size="sm" py={4} flex="1">
                Apply
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </>
  );
};

export default GlobalView;
