import { useMsal } from "@azure/msal-react";
import {
  Box,
  Center,
  Container,
  Divider,
  Flex,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import Arrow from "innovate/assets/images/arrow.svg";
import Bussiness from "innovate/assets/images/business.svg";
import Casrsimg from "innovate/assets/images/casrsimg.svg";
import homeBackground from "innovate/assets/images/homeBackground.png";
import InnobrewLight from "innovate/assets/images/innobrew_light.svg";
import InnobrewDark from "innovate/assets/images/InnoBrewDark.svg";
import innoExprolerDark from "innovate/assets/images/innoExprolerDark.svg";
import innoExprolerLight from "innovate/assets/images/innoExprolerLight.svg";
import InnoimpactLight from "innovate/assets/images/innoimpact_light.svg";
import InnoimpactDark from "innovate/assets/images/InnoImpactDark.svg";
import Innovation1 from "innovate/assets/images/innovation1.png";
import Innovation2 from "innovate/assets/images/innovation2.png";
import Innovation3 from "innovate/assets/images/innovation3.png";
import Innovation4 from "innovate/assets/images/innovation4.png";
import FooterLogo from "innovate/assets/images/logosSets/LogoWide/SVG/LogoWideYellowBlack.svg";
import InnovateXai from "innovate/assets/images/logosSets/LogoWide/SVG/LogoWideYellowWhite.svg";
import first from "innovate/assets/images/StakeHolders/first.png";
import fourth from "innovate/assets/images/StakeHolders/fourth.png";
import second from "innovate/assets/images/StakeHolders/second.png";
import third from "innovate/assets/images/StakeHolders/third.png";
import Target from "innovate/assets/images/target.svg";
import PublicHeader from "innovate/components/common/PublicHeader";
import WorldMap from "innovate/components/WorldMap/WorldMap";
import React from "react";
import { Link, redirect, useNavigate } from "react-router-dom";

const Homepage: React.FC = () => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const exploreLink = () => {
    navigate("/inno-explore");
  };
  const handleLogin = () => {
    if (accounts.length > 0) {
      navigate("/pre-launch");
    } else {
      instance.loginRedirect();
    }
  };
  const powerbiRedirect = () => {
    window.open(
      "https://app.powerbi.com/groups/c8c6448c-c890-4334-b5eb-e45531e82445/reports/60978a8c-0567-4650-97b2-ca03645e41b7/b52ce17f64900e5b704a?experience=power-bi",
      "_blank"
    );
  };
  const handleRedirect = async () => {
    try {
      const response = await instance.handleRedirectPromise();
      if (response !== null) {
        window.location.href = "/pre-launch";
      }
    } catch (e) {
      console.error(e);
    }
  };

  handleRedirect();

  return (
    <Box backgroundColor="white">
      <PublicHeader />

      <Box
        bgImage={homeBackground}
        bgSize="cover"
        bgPosition="center"
        h="300px"
      >
        <Container maxWidth="container.xl">
          <Box w="57%" mx="auto" pt="60px" textAlign="center">
            <Text
              textStyle="heading"
              color="white"
              as="b"
              className="banner_title"
            >
              Welcome to
            </Text>
            <Center mt={2} mb={3}>
              <Image src={InnovateXai} alt="InnovateX.ai Logo" w="120px" />
            </Center>
            <Text className="banner_text">
              Plan, Launch and Track Innovation
            </Text>
          </Box>

          <Box w="85%" mx="auto">
          {/* <Box w="97%" mx="auto"> */}
            <SimpleGrid
             columns={[1, 1, 2, 2]}
             spacingX="10px"
             spacingY="0px"
              // columns={[1, 1, 3, 3]} // Ensure 3 columns on larger screens
              // spacingX="10px" // Add some spacing between columns
              // spacingY="10px" // Add some spacing between rows
              justifyContent="center"
              p={[2, 2, 5, 12]}
            >
              {/* <Box
                className="prelaunch_box"
                onClick={() => exploreLink()}
                cursor="pointer"
                w="100%" // Ensure the box takes full width of the column
              >
                <Stack direction="row" spacing={4} p="4%" alignItems="center">
                  <Image
                    src={innoExprolerLight}
                    alt="Prelaunch Logo"
                    h="90px"
                    className="initial_img"
                  />
                  <Image
                    src={innoExprolerDark}
                    alt="Prelaunch Logo"
                    h="90px"
                    className="hover_img"
                  />
                  <Stack>
                    <Text color="#797979" p={0} className="box_content">
                      <Text
                        fontSize={{ base: "14px", md: "16px", lg: "16px" }}
                        className="box_text"
                        as="b"
                      >
                        InnoExplore
                      </Text>
                    </Text>
                    <Text
                      fontSize={{ base: "12px", md: "12px", lg: "14px" }}
                      className="box_paragraph"
                      color="#797979"
                      p={0}
                    >
                      OBBPC framework to identify Price/Pack innovations
                      opportunity
                    </Text>
                  </Stack>
                </Stack>
              </Box> */}
              <Box
                className="prelaunch_box"
                onClick={() => handleLogin()}
                cursor="pointer"
                w="100%" // Ensure the box takes full width of the column
              >
                <Stack direction="row" spacing={4} p="4%" alignItems="center">
                  <Image
                    src={InnobrewLight}
                    alt="Prelaunch Logo"
                    h="90px"
                    className="initial_img"
                  />
                  <Image
                    src={InnobrewDark}
                    alt="Prelaunch Logo"
                    h="90px"
                    className="hover_img"
                  />
                  <Stack>
                    <Text color="#797979" p={0} className="box_content">
                      <Text
                        fontSize={{ base: "14px", md: "16px", lg: "16px" }}
                        className="box_text"
                        as="b"
                      >
                        InnoBrew{" "}
                      </Text>
                      (Pre-launch)
                    </Text>
                    <Text
                      fontSize={{ base: "12px", md: "12px", lg: "14px" }}
                      className="box_paragraph"
                      color="#797979"
                      p={0}
                    >
                      Is my innovation good enough to be launched?
                    </Text>
                  </Stack>
                </Stack>
              </Box>
              <Box onClick={() => powerbiRedirect()}>
                <Box
                  className="postlaunch_box"
                  cursor="pointer"
                  mt={[2, 2, 0]}
                  w="100%"
                >
                  <Stack direction="row" spacing={4} p="4%" alignItems="center">
                    <Image
                      src={InnoimpactLight}
                      alt="Prelaunch Logo"
                      h="90px"
                      className="initial_img"
                    />
                    <Image
                      src={InnoimpactDark}
                      alt="Prelaunch Logo"
                      h="90px"
                      className="hover_img"
                    />
                    <Stack>
                      <Text color="#797979" p={0} className="box_content">
                        <Text
                          fontSize={{ base: "14px", md: "16px", lg: "16px" }}
                          className="box_text"
                          as="b"
                        >
                          InnoImpact{" "}
                        </Text>
                        (Post-launch)
                      </Text>
                      <Text
                        fontSize={{ base: "12px", md: "12px", lg: "14px" }}
                        className="box_paragraph"
                        color="#797979"
                      >
                        How good is the launched innovation?
                      </Text>
                    </Stack>
                  </Stack>
                </Box>
              </Box>
            </SimpleGrid>
          </Box>
        </Container>
      </Box>

      <Container maxW="container.xl" mt={[180, 180, 120]}>
        <Text className="section_titleblack">Featured Offer</Text>
        <Box w="92%" mx="auto">
          <SimpleGrid
            columns={[1, 2, 3, 4]}
            spacingX="0px"
            spacingY="0px"
            justifyContent="center"
            p={5}
          >
            <Box bg="#fff" m="2" className="feature_cards">
              <Box p="4" className="side front">
                <Image src={Casrsimg} h="90px" w="100%" objectFit="contain" />
                <Text
                  mt="2"
                  className="feature_text"
                  fontSize={{ base: "12px", md: "14px", lg: "14px" }}
                >
                  Short/ Long term
                  <br /> Volume Potential
                </Text>
              </Box>
              <Box className="side back">
                <Text
                  fontSize={{ base: "12px", md: "14px", lg: "14px" }}
                  color="black"
                >
                  Volume Potential
                </Text>
                <Text
                  fontSize={{ base: "10px", md: "14px", lg: "12px" }}
                  color="black"
                >
                  Estimate 1YP/3YP volume potential for innovation product using
                  ML based technique learning from historical sales pattern of
                  similar SKUs
                </Text>
              </Box>
            </Box>
            <Box bg="#fff" m="2" className="feature_cards">
              <Box p="4" className="side front">
                <Image src={Target} h="90px" w="100%" />
                <Text
                  fontSize={{ base: "12px", md: "14px", lg: "14px" }}
                  className="feature_text"
                >
                  Cannibalization Impact
                </Text>
              </Box>
              <Box className="side back">
                <Text
                  fontSize={{ base: "12px", md: "14px", lg: "14px" }}
                  color="black"
                >
                  Cannibalization Impact
                </Text>
                <Text
                  fontSize={{ base: "10px", md: "12px", lg: "12px" }}
                  color="black"
                >
                  Calculate volume & financials cannibalized from internal SKUs
                  to innovation product.
                </Text>
              </Box>
            </Box>

            <Box bg="#fff" m="2" className="feature_cards">
              <Box p="4" className="side front">
                <Image src={Bussiness} h="90px" w="100%" />
                <Text
                  fontSize={{ base: "12px", md: "14px", lg: "14px" }}
                  className="feature_text"
                >
                  Track Innovations
                </Text>
              </Box>
              <Box className="side back">
                <Text
                  fontSize={{ base: "12px", md: "14px", lg: "14px" }}
                  color="black"
                >
                  Track Innovation
                </Text>
                <Text
                  fontSize={{ base: "10px", md: "12px", lg: "12px" }}
                  color="black"
                >
                  Analyze innovation product performance post launch on multiple
                  parameters in PowerBI dashboard
                </Text>
              </Box>
            </Box>

            <Box bg="#fff" m="2" className="feature_cards">
              <Box p="4" className="side front">
                <Image src={Arrow} h="90px" w="100%" />
                <Text
                  fontSize={{ base: "12px", md: "14px", lg: "14px" }}
                  className="feature_text"
                >
                  Measure Incrementality
                </Text>
              </Box>
              <Box className="side back">
                <Text
                  fontSize={{ base: "12px", md: "14px", lg: "14px" }}
                  color="black"
                >
                  Measure Incrementality
                </Text>
                <Text
                  fontSize={{ base: "10px", md: "12px", lg: "12px" }}
                  color="black"
                >
                  Calculate volume & financials contributed additionally to the
                  portfolio from innovation product
                </Text>
              </Box>
            </Box>
          </SimpleGrid>
        </Box>
      </Container>

      <Container maxW="container.xl" mt="5%">
        <Text className="section_titleblack">Pillars of Innovation</Text>
        <Box w="92%" mx="auto">
          <SimpleGrid
            columns={[1, 2, 3, 4]}
            spacingX="0px"
            spacingY="0px"
            justifyContent="center"
            p={5}
          >
            <Box bg="#fff" m="2">
              <Box className="innovation_cards">
                <Text className="number_rounds">1</Text>
                <Text className="title_innovate">Portfolio Optimization</Text>
                <Box className="card-component">
                  <Image src={Innovation1} className="innovation_img" />
                  <Box className="contents">
                    {/* <Text fontSize="lg" color="black">Lorem Ipsum</Text> */}
                    <Text
                      className="innovation_textcard"
                      fontSize={{ base: "10px", md: "12px", lg: "12px" }}
                      color="black"
                    >
                      Integration with AI PHO (Scope 2025)
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box bg="#fff" m="2">
              <Box className="innovation_cards innovation_cards2">
                <Text className="number_rounds">2</Text>
                <Text className="title_innovate">Pack/Price</Text>
                <Box className="card-component">
                  <Image src={Innovation2} className="innovation_img" />
                  <Box className="contents">
                    {/* <Text fontSize="lg" color="black">Lorem Ipsum</Text> */}
                    <Text
                      className="innovation_textcard"
                      fontSize={{ base: "10px", md: "12px", lg: "12px" }}
                      color="black"
                    >
                      An existing brand with a new size or pack type, Track:
                      Monthly performance, Stage Gate 4 Evaluation
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box bg="#fff" m="2">
              <Box className="innovation_cards innovation_cards2">
                <Text className="number_rounds">3</Text>
                <Text className="title_innovate">Renovation</Text>
                <Box className="card-component">
                  <Image src={Innovation3} className="innovation_img" />
                  <Box className="contents">
                    {/* <Text fontSize="lg" color="black">Lorem Ipsum</Text> */}
                    <Text
                      className="innovation_textcard"
                      fontSize={{ base: "10px", md: "12px", lg: "12px" }}
                      color="black"
                    >
                      {" "}
                      Relaunching of existing product with complete new
                      packaging, Track: Monthly performance, Stage Gate 4
                      Evaluation
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box bg="#fff" m="2">
              <Box className="innovation_cards innovation_cards2">
                <Text className="number_rounds">4</Text>
                <Text className="title_innovate">New Opportunities</Text>
                <Box className="card-component">
                  <Image src={Innovation4} className="innovation_img" />
                  <Box className="contents">
                    {/* <Text fontSize="lg" color="black">Lorem Ipsum</Text> */}
                    <Text
                      className="innovation_textcard"
                      fontSize={{ base: "12px", md: "12px", lg: "12px" }}
                      color="black"
                    >
                      Launch of new brand/category, Track: Monthly performance,
                      Stage Gate 4 Evaluation
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          </SimpleGrid>
        </Box>
      </Container>

      <Box bgColor="#242424" mt={[50, 50, 20, 10]}>
        <Container maxW="container.xl" mt="3%" pt="3%" pb="3%">
          <Text className="section_titlewhite">Key Highlights</Text>
          <Box w="100%" mx="auto">
            <SimpleGrid
              columns={[1, 2, 3]}
              spacingX="0px"
              spacingY="0px"
              justifyContent="center"
              p={5}
            >
              <Box className="key_highlight" m="2">
                <Text as="h2" className="customHeading">
                  10
                </Text>
                <Text className="customText">Innovations</Text>
              </Box>
              <Box className="key_highlight" m="2">
                <Text as="h2" className="customHeading">
                  159.8 KHL
                </Text>
                <Text className="customText">Incremental Volume</Text>
              </Box>
              <Box className="key_highlight" m="2">
                <Text as="h2" className="customHeading">
                  $ 14.1 M
                </Text>
                <Text className="customText">Incremental MaCo</Text>
              </Box>
            </SimpleGrid>
          </Box>
        </Container>
      </Box>

      <Box>
        <Container maxW="container.xl" pt="2%">
          <Text my={16} className="section_titleblack">
            Global Presence
          </Text>
          <Box w="85%" mx="auto">
            <Box bgColor="#242424" p={5} borderRadius={10}>
              <Flex>
                <Box w="32%" className="white_font">
                  <Text
                    fontSize={{ base: "10px", md: "14px", lg: "14px" }}
                    fontWeight={600}
                  >
                    Global Innovations & Category
                  </Text>
                  <Divider
                    width="30%"
                    mt={1}
                    borderColor="#D0A23B"
                    borderWidth="2px"
                  />
                  <Flex direction="column" m={7}>
                    <Box display="flex" alignItems="center" mb={4}>
                      <Image
                        borderRadius="full"
                        boxSize="35px"
                        src={first}
                        alt="Dan Abramov"
                        mr={2}
                      />
                      <Box>
                        <Text
                          fontSize={{ base: "10px", md: "12px", lg: "12px" }}
                          fontWeight={600}
                        >
                          Bruno Marsillac
                        </Text>
                        <Text fontSize="10px" color="#FFFFFF99">
                          Global VP Category Participation
                        </Text>
                      </Box>
                    </Box>

                    <Box display="flex" alignItems="center" mb={4}>
                      <Image
                        borderRadius="full"
                        boxSize="35px"
                        src={second}
                        alt="Dan Abramov"
                        mr={2}
                      />
                      <Box>
                        <Text
                          fontSize={{ base: "10px", md: "12px", lg: "12px" }}
                          fontWeight={600}
                        >
                          Sebnem Erim
                        </Text>
                        <Text fontSize="10px" color="#FFFFFF99">
                          Global VP Innovations
                        </Text>
                      </Box>
                    </Box>

                    <Box display="flex" alignItems="center" mb={4}>
                      <Image
                        borderRadius="full"
                        boxSize="35px"
                        src={third}
                        alt="Dan Abramov"
                        mr={2}
                      />
                      <Box>
                        <Text
                          fontSize={{ base: "10px", md: "12px", lg: "12px" }}
                          fontWeight={600}
                        >
                          Juan Giovaneli
                        </Text>
                        <Text fontSize="10px" color="#FFFFFF99">
                          Global Director OBPPC
                        </Text>
                      </Box>
                    </Box>

                    <Box display="flex" alignItems="center" mb={4}>
                      <Image
                        borderRadius="full"
                        boxSize="35px"
                        src={fourth}
                        alt="Dan Abramov"
                        mr={2}
                      />
                      <Box>
                        <Text
                          fontSize={{ base: "10px", md: "12px", lg: "12px" }}
                          fontWeight={600}
                        >
                          Javier Rank
                        </Text>
                        <Text fontSize="10px" color="#FFFFFF99">
                          Innovations Manager
                        </Text>
                      </Box>
                    </Box>
                  </Flex>
                </Box>
                <Box w="88%" mr="40px">
                  <WorldMap />
                </Box>
              </Flex>
            </Box>
          </Box>
        </Container>
      </Box>

      <Box as="footer" bg="#fff" color="white" p={4} textAlign="center">
        <Container maxW="container.xl" pt="2%">
          <Box w="92%" mx="auto">
            <SimpleGrid columns={[1, 2, 3]} spacingX="0px" spacingY="0px">
              <Box m="2">
                <Image src={FooterLogo} w="120px" />
                <Text
                  fontSize={{ base: "10px", md: "14px", lg: "14px" }}
                  color="black"
                  className="logo_textfooter"
                >
                  Plan, Build and Track Innovation
                </Text>
              </Box>
              <Box m="2" textAlign="left">
                <Text
                  fontSize={{ base: "10px", md: "14px", lg: "14px" }}
                  color="black"
                  fontWeight={600}
                >
                  Modules
                </Text>
                <Text
                  fontSize={{ base: "10px", md: "14px", lg: "14px" }}
                  color="black"
                  py={1}
                >
                  InnoBrew(Pre Launch)
                </Text>
                <Text
                  fontSize={{ base: "10px", md: "14px", lg: "14px" }}
                  color="black"
                  py={1}
                >
                  InnoImpact(Post Launch)
                </Text>
              </Box>
              <Box m="2">
                <Text
                  fontSize={{ base: "10px", md: "14px", lg: "14px" }}
                  color="black"
                  textAlign="center"
                  fontWeight={600}
                >
                  Contact us
                </Text>
                <Text
                  fontSize={{ base: "12px", md: "14px", lg: "14px" }}
                  color="black"
                  textAlign="center"
                  className="email_footer"
                >
                  innovateX@ab-inbev.com
                </Text>
              </Box>
            </SimpleGrid>
          </Box>
        </Container>
      </Box>
      <Divider />
      <Box textAlign="center" pt="30px" pb="70px">
        <Text fontSize={{ base: "10px", md: "14px", lg: "14px" }}>
          {" "}
          Copyright ® 2024 AB-InBev All rights Reserved
        </Text>
      </Box>
    </Box>
  );
};
export default Homepage;
