import Unauthorized from "innovate/pages/Unauthorized/Unauthorized";
import withApprovalProvider from "innovate/utils/withApprovalProvider";
import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";

import adminRoutes from "./adminRoutes";
import InnoExplore from "./pages/InnoExplore/InnoExplore";
import GlobalView from "./pages/InnoExplore/GlobalView";

const Homepage = lazy(() => import("../src/pages/Homepage/Homepage"));
const Request = lazy(() => import("./pages/RequestPage/RequestPage"));
const PreLaunchPage = lazy(() => import("./pages/Pre-launch/PreLaunchPage"));
const ComparePage = lazy(() => import("./pages/ComparePage/ComparePage"));
const ResultPage = lazy(() => import("./pages/ResultPage/ResultPage"));
const ErrorPage = lazy(() => import("./pages/Unauthorized/ErrorPage"));

const router = createBrowserRouter([
  {
    path: "/",
    children: [
      {
        index: true,
        Component: Homepage,
      },
      {
        path: "inno-explore",
        children: [
          {
            index: true,
            Component: withApprovalProvider(<InnoExplore />),
          },
        ],
      },
      {
        path: "global-view",
        children: [
          {
            index: true,
            Component: withApprovalProvider(<GlobalView />),
          },
        ],
      },
      {
        path: "pre-launch",
        children: [
          {
            index: true,
            Component: withApprovalProvider(<PreLaunchPage />),
          },
          {
            path: "compare",
            Component: withApprovalProvider(<ComparePage />),
          },
          {
            path: "new-simulation",
            Component: withApprovalProvider(<ResultPage />),
          },
          {
            path: "edit-simulation",
            Component: withApprovalProvider(<ResultPage />),
          },
        ],
      },
      {
        path: "/404",
        Component: ErrorPage,
      },
      {
        path: "access",
        children: [
          {
            index: true,
            Component: withApprovalProvider(<Request />),
          },
        ],
      },
    ],
  },
  ...adminRoutes,
]);

export default router;
