import { Input, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { TableCellRendererProps } from "./types";

export const clientId =
  import.meta.env.VITE_CLIENT_ID ?? "5d7c1d57-b6fd-4f46-bd7c-c9589ebdfef1";
export const redirectUri =
  import.meta.env.VITE_REDIRECT_URI ?? "https://innovatex.ai-stg.ab-inbev.com";
export const fixed =
import.meta.env.VITE_API_URL ?? "https://dev.innox.ab-inbev.com/api/";


export const categoryDistributionRSA = { BEER: 6.11, "BEYOND BEER": 6.15 };
export const categoryDistributionBR = {
  BEER: 4.9,
  "BEYOND BEER": 22.8,
  NAB: 21.8,
  "NON ALC BEER": 5.9,
};
export const pptData = [
  {
    Zone: "Africa",
    Country: "South Africa",
    Financials_source: "UMAMI",
    Refresh_year: 2024,
    Refresh_month: "August",
  },
  {
    Zone: "SAZ",
    Country: "Brazil",
    Financials_source: "CUBO",
    Refresh_year: 2024,
    Refresh_month: "August",
  },
];

export const filterByCountry = (country: string) =>
  pptData.filter((item) => item.Country === country)[0];

export const chakraStyles = (
  customWidth: string,
  customFont: string,
  customAlign: string
) => ({
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    width: customWidth,
    fontSize: customFont,
    fontWeight: "600",
    textAlign: customAlign,
    color: "black",
    bg: "white",
    padding: "0 16px 0",
    backgroundColor: "white !important",
    // border: "none",
    borderColor: state.isFocused
      ? "primaryBlack !important"
      : "border !important",
    borderRadius: "8px",
    boxShadow: "none !important",
  }),
  placeholder: (baseStyles: any) => ({
    ...baseStyles,
    color: "#1E1E1ECC",
  }),
  valueContainer: (baseStyles: any) => ({
    ...baseStyles,
    px: "0",
  }),
  multiValue: (baseStyles: any) => ({
    ...baseStyles,
    bg: "border",
    fontSize: "12px",
  }),
  dropdownIndicator: (baseStyles: any) => ({
    ...baseStyles,
    px: "200px",
    bg: "green",
    cursor: "pointer",
  }),
  option: (baseStyles: any, state: any) => ({
    ...baseStyles,
    color: "textBlack !important",
    fontSize: "12px",
    background: state.isFocused ? "border !important" : "none",
  }),
});
export const filterInfo: { [key: string]: string } = {
  // Brand: "Brand Name",
  // "Beer Type": "Type of beer",
  // "Pack Size": "Volume size of the pack. i.e. 330 ml",
  // "Price Segment": "SKU falling in the four core price segments",
  // ABV: "Alcohol by volume %",
  // "Pack/Return Type": "Container type of the pack",
  // "Launch Period": "Which quarter innovation is going to be launched?",
  // Districts: "Targeted district for the innovation to be launched",
  // "POC Segment": "Segments where innovation is planning to be launched",
  "Price per HL": "Expected NR per HL",
  "MACO per HL": "Unplugged MACO/HL",
  Distribution: "% of POC where the product will be available",
};

export const RegionalColumn = [
  {
    header: "Districts",
    accessorKey: "R_Districts",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
  {
    header: "POC Segments",
    accessorKey: "R_POC_Segments",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
  {
    header: "NR per HL (USD)",
    accessorKey: "R_Price_per_HL",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
  {
    header: "Distribution %",
    accessorKey: "R_Distribution",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
];
export const BrazilRegionalColumn = [
  {
    header: "Regions",
    accessorKey: "R_Regions",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
  {
    header: "Channels",
    accessorKey: "R_Channels",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
  {
    header: "NR per HL (USD)",
    accessorKey: "R_Price_per_HL",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
  {
    header: "Distribution %",
    accessorKey: "R_Distribution",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
];

export const TableCellRenderer: React.FC<TableCellRendererProps> = React.memo(
  ({ cellProps, onDataChange }) => {
    const { metric } = cellProps.row.original;
    const value = cellProps.getValue() as string;
    const year = cellProps.column.id;

    const handleBlur = (yea3r: string, value3: string) => {
      onDataChange(yea3r, value3); // Call the parent callback to pass data up
    };

    if (metric === "Value") {
      return (
        <Input
          fontSize="11px"
          border="1px solid #a7a4a4"
          fontWeight="700"
          fontFamily="Avantt"
          defaultValue={value}
          onChange={(e) => handleBlur(year, e.target.value)} // Update the value on blur
        />
      );
    }

    return <Text>{value}</Text>;
  }
);
