import { useMsal } from "@azure/msal-react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Divider,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { GetArpprovedCountries } from "innovate/api/api";
import brazil from "innovate/assets/flags/Brazil.svg";
import south from "innovate/assets/flags/SouthAfrica.svg";
import logo from "innovate/assets/images/abi_logo.png";
import userImg from "innovate/assets/images/admin.svg";
import global from "innovate/assets/images/Global.svg";
import Group from "innovate/assets/images/Group.svg";
import InnovateX from "innovate/assets/images/logosSets/LogoWide/PNG/LogoWideYellowWhite.png";
import logOut from "innovate/assets/images/Logout.svg";
import manageAccounts from "innovate/assets/images/manage_accounts.svg";
import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";

// eslint-disable-next-line no-restricted-imports
import { ApprovalContext } from "../ApprovalProvider";

interface Props {}

const Header: React.FC<Props> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { approvedCountries } = GetArpprovedCountries();
  const [zoneImg, setZoneImg] = useState("");

  const currentPath = location.pathname;
  const { userName, userType } = useContext(ApprovalContext);

  const isActive = (paths: string) => {
    const pathArray = paths.split(",");
    return pathArray.includes(currentPath)
      ? {
          textDecoration: "underline",
          textDecorationThickness: "2px",
          textUnderlineOffset: "5px",
          color: "#FFFFFF",
        }
      : { color: "#848484" };
  };

  const { instance } = useMsal();

  const logout = () => {
    instance.logoutPopup({
      postLogoutRedirectUri: "/",
      mainWindowRedirectUri: "/",
    });
  };

  const navigateToAccess = () => {
    navigate("/access", { state: { updateAccess: true } });
  };
  const navigateToAdmin = () => {
    navigate("/admin", { state: { updateAccess: true } });
  };

  useEffect(() => {
    if (approvedCountries.includes("Brazil")) {
      setZoneImg(brazil);
    } else if (approvedCountries.includes("South Africa")) {
      setZoneImg(south);
    }

    if (approvedCountries.length === 2) {
      setZoneImg(global);
    }
  }, [approvedCountries]);

  return (
    <Flex
      position="sticky"
      top="0"
      zIndex="sticky"
      borderBottom="solid 1px"
      borderColor="border"
      width="100%"
      alignItems="center"
      justifyContent="space-between"
      padding="10px 24px"
      bg={useColorModeValue("black", "black.900")}
    >
      <Flex align="center" columnGap="30px">
        <Link to="/">
          <Image w="106px" src={logo} alt="loginFootLogo" />
        </Link>

        <Link to="/">
          <Image w="110px" src={InnovateX} alt="loginFootLogo" />
        </Link>
      </Flex>
      <Flex align="center" columnGap="80px" fontWeight="bold">
        <NavLink to="/" style={isActive("/")}>
          Home
        </NavLink>
        {/* <NavLink
          to="/inno-explore"
          style={isActive("/inno-explore,/global-view,")}
        >
          InnoExplore
        </NavLink> */}
        <NavLink
          to="/pre-launch"
          style={isActive(
            "/pre-launch,/pre-launch/compare,/pre-launch/new-simulation,/admin,/pre-launch/edit-simulation"
          )}
        >
          InnoBrew
        </NavLink>

        <NavLink
          target="_blank"
          to="https://app.powerbi.com/groups/c8c6448c-c890-4334-b5eb-e45531e82445/reports/60978a8c-0567-4650-97b2-ca03645e41b7/b52ce17f64900e5b704a?experience=power-bi"
          style={isActive("")}
        >
          InnoImpact
        </NavLink>
      </Flex>
      <Flex alignItems="center" as="nav" columnGap="12px">
        <Menu>
          <MenuButton
            as={Button}
            bg="none"
            rightIcon={<ChevronDownIcon color="white" />}
          >
            <Flex align="center">
              <Text color="white"> {userName} </Text>
            </Flex>
          </MenuButton>

          <MenuList>
            <MenuItem
              icon={<img src={userImg} alt="userType" />}
              _hover={{ bg: "transparent" }}
              _focus={{ bg: "transparent" }}
              cursor="default"
              mb={2}
            >
              <Flex direction="column">
                <Text
                  color="#8080809e"
                  fontWeight={600}
                  fontSize="14px"
                  textTransform="capitalize"
                >
                  {userType}
                </Text>
              </Flex>
            </MenuItem>
            <Tooltip label="List of accessible markets" placement="bottom">
              <MenuItem
                icon={<img src={zoneImg} width="20px" alt="country" />}
                _hover={{ bg: "transparent" }}
                _focus={{ bg: "transparent" }}
                cursor="default"
                mb={2}
              >
                <Flex direction="column">
                  <Text
                    color="#8080809e"
                    fontWeight={600}
                    fontSize="14px"
                    textTransform="capitalize"
                  >
                    {approvedCountries.join(", ")}
                  </Text>
                  <Text fontSize="sm" color="gray.500" />
                </Flex>
              </MenuItem>
            </Tooltip>
            <Divider />
            {userType === "admin" && (
              <MenuItem
                _hover={{ bg: "#dedede" }}
                _focus={{ bg: "#dedede" }}
                icon={<img src={manageAccounts} alt="Admin" />}
                onClick={navigateToAdmin}
                my={2}
              >
                <Flex direction="column">
                  <Text fontWeight="600" fontSize="14px">
                    Go To Admin
                  </Text>
                </Flex>
              </MenuItem>
            )}
            <Tooltip
              label="Request new or edit existing access"
              placement="bottom"
            >
              <MenuItem
                _hover={{ bg: "#dedede" }}
                _focus={{ bg: "#dedede" }}
                icon={<img src={Group} alt="Group" />}
                onClick={navigateToAccess}
                my={2}
              >
                <Flex direction="column">
                  <Text fontWeight={600} fontSize="14px">
                    Raise New/Edit Access
                  </Text>
                </Flex>
              </MenuItem>
            </Tooltip>
            <MenuItem
              icon={<img src={logOut} alt="logout" />}
              mb={2}
              _hover={{ bg: "#dedede" }}
              _focus={{ bg: "#dedede" }}
              onClick={logout}
            >
              <Flex direction="column">
                <Text fontWeight={600} fontSize="14px">
                  Log Out
                </Text>
              </Flex>
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
};

export default Header;
