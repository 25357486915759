import { IMsalContext, useMsal } from "@azure/msal-react";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import axios, { type AxiosRequestConfig, AxiosResponse } from "axios";
import { fixed } from "innovate/utils/Constants";
import { ApprovedUser } from "innovate/utils/types";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AUTH_TOKEN_KEY = "Auth_token";
export type Response<T> =
  | {
      simulation: boolean;
      success: true;
      data: T;
    }
  | { success: false; err?: AxiosResponse | undefined };

type AuthResponse = { token_type: string; access_token: string };
type NavigateFunction = ReturnType<typeof useNavigate>;

const getAuthToken = async (
  msalDetails: IMsalContext,
  navigate: NavigateFunction
) => {
  if (!sessionStorage.getItem(AUTH_TOKEN_KEY)) {
    const account = msalDetails.accounts[0];
    const postData = {
      email: account?.username,
      username: account?.name,
    };
    let authResponse;
    try {
      authResponse = await axios<AuthResponse>({
        url: `${fixed}users/sso-login`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: postData,
      });
    } catch (err) {
      navigate("/404", {
        state: {
          apiEndpoint: `${fixed}users/sso-login`,
          status: authResponse?.status,
        },
      });
      console.debug("error in getting token");
      return null;
    }
    console.debug("error in getting token");

    if (authResponse.status === 200) {
      sessionStorage.setItem(AUTH_TOKEN_KEY, authResponse.data.access_token);
    } else if (authResponse.status === 404) {
      navigate("/404", {
        state: { apiEndpoint: `${fixed}users/sso-login`, status: "404" },
      });
      return null;
    } else {
      navigate("/404", {
        state: {
          apiEndpoint: `${fixed}users/sso-login`,
          status: authResponse.status,
        },
      });
      console.error(authResponse);
      return null;
    }
  }
  const token = sessionStorage.getItem(AUTH_TOKEN_KEY);
  if (token) {
    return `Bearer ${token}`;
  } else {
    return null;
  }
};

export const useIsAuthorized = () => {
  const msalDetails = useMsal();
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      try {
        await getAuthToken(msalDetails, navigate);
      } catch (err) {
        console.debug(err);
      }
    })();
  }, [msalDetails, navigate]);
};

// const httpCall = async <T>(
//   config: AxiosRequestConfig,
//   msalDetails: IMsalContext,
//   navigate: NavigateFunction,
// ): Promise<Response<T>> => {
//   const token = await getAuthToken(msalDetails, navigate);
//   if (!token) return { success: false };

//   const headerConfig: AxiosRequestConfig = {
//     ...config,
//     headers: {
//       ...(config.headers ?? {}),
//       Authorization: token,
//       "Cache-Control": "no-store,no-cache,",
//       Pragma: "no-cache",
//     },
//   };
//   try {
//     const response = await axios<T>(headerConfig);
//     return {
//       success: true,
//       data: response.data,
//     };
//   } catch (err) {
//     console.error(err);

//     // navigate("/404");
//     if (axios.isAxiosError(err)) {
// const statusCode = err.response?.status;
// navigate("/404", {
//   state: { apiEndpoint: config.url, status: statusCode },
// });
//       if (err.response?.status === 401) {
//         sessionStorage.removeItem(AUTH_TOKEN_KEY);
//         return { success: false, err: err.response };
//       } else {
//         return { success: false, err: err.response };
//       }
//     }
//     return { success: false };
//   }
// };
const httpCall = async <T>(
  config: AxiosRequestConfig,
  msalDetails: IMsalContext,
  navigate: NavigateFunction
): Promise<Response<T>> => {
  const token = await getAuthToken(msalDetails, navigate);
  if (!token) return { success: false };

  const headerConfig: AxiosRequestConfig = {
    ...config,
    headers: {
      ...(config.headers ?? {}),
      Authorization: token,
      "Cache-Control": "no-store,no-cache,",
      Pragma: "no-cache",
    },
  };
  try {
    const response = await axios<T>(headerConfig);
    return {
      success: true,
      data: response.data,
    };
  } catch (err) {
    // console.error(err);
    if (axios.isAxiosError(err)) {
      if (err.response?.status === 401) {
        console.log("Token expired 401");
        sessionStorage.removeItem(AUTH_TOKEN_KEY);
        // return { success: false, err: err.response };
        const newToken = await getAuthToken(msalDetails, navigate);
        if (!newToken) return { success: false };
        console.log("newToken", newToken);
        if (newToken) {
          // Retry the original request with the new token
          const retryConfig: AxiosRequestConfig = {
            ...config,
            headers: {
              ...(config.headers ?? {}),
              Authorization: newToken,
              "Cache-Control": "no-store,no-cache,",
              Pragma: "no-cache",
            },
          };
          try {
            const retryResponse = await axios<T>(retryConfig);
            return {
              success: true,
              data: retryResponse.data,
            };
          } catch (retryErr) {
            console.error("retryErr", retryErr);
            return { success: false, err: retryErr.response };
          }
        } else {
          return { success: false, err: err.response };
        }
      } else {
        const statusCode = err.response?.status;
        console.log("Token expired unknown", statusCode);
        // navigate("/404", {
        //   state: { apiEndpoint: config.url, status: statusCode },
        // });
        return { success: false, err: err.response };
      }
    }
    return { success: false };
  }
};
export const useAuthenticatedQuery = <T>(
  queryKey: unknown[],
  queryConfig: AxiosRequestConfig,
  options?: {
    refetchOnWindowFocus?: boolean;
    enabled?: boolean;
  }
): UseQueryResult<Response<T>> => {
  const msalDetails = useMsal();
  const navigate = useNavigate();
  const result = useQuery({
    queryKey,
    queryFn: () => httpCall<T>(queryConfig, msalDetails, navigate),
    refetchOnWindowFocus: options?.refetchOnWindowFocus ?? false,
    retry: false,
    enabled: options?.enabled ?? true, // Only run the query if enabled is true
  });
  const { isPending, error, data } = result;
  useEffect(() => {
    if (error) {
      console.error(error);
    } else if (import.meta.env.DEV) {
      // if (isPending) {
      //   console.debug(queryKey, "Pending");
      // } else {
      //   console.debug(queryKey, data);
      // }
    }
  }, [error, isPending, data, queryKey]);
  return result;
};

export const useAuthenticatedMutation = <T, MutationData>(
  mutationKey: unknown[],
  getMutationConfig: (data: MutationData) => AxiosRequestConfig
): UseMutationResult<Response<T>, unknown, MutationData, unknown> => {
  const msalDetails = useMsal();
  const navigate = useNavigate();
  const result = useMutation<Response<T>, unknown, MutationData, unknown>({
    mutationKey,
    mutationFn: (data: MutationData) =>
      httpCall<T>(getMutationConfig(data), msalDetails, navigate),
    retry: false,
  });
  const { isIdle, error, data } = result;
  useEffect(() => {
    if (error) {
      console.error("error");
    } else if (import.meta.env.DEV) {
      // if (isIdle) {
      //   console.debug(mutationKey, "Idle");
      // } else {
      //   console.debug(mutationKey, data);
      // }
    }
  }, [error, isIdle, data, mutationKey]);
  return result;
};
type ResponseData = {
  is_admin: boolean;
  success: boolean;
  data?: {
    is_admin?: boolean;
  };
};
type scenarioData = {
  simulations: any;
  success: boolean;
};
interface CategoryData {
  distribution: number;
  price_per_hl: number;
  abv: number;
}

interface DataStructure {
  BEER: CategoryData;
  BEYOND_BEER: CategoryData;
  // Add other categories if needed
}

type approvalResponseData = {
  rejected: any[];
  approved: any[];
  success: boolean;
  data?: {
    approved: any[];
    rejected: any[];
  };
};

export const useAdminAuthorized = () => {
  const { data, isLoading, isError } = useAuthenticatedQuery<ResponseData>(
    ["getUser"],
    {
      url: `${fixed}users/is_admin`,
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  );


  const isAdmin = data?.success ? data.data?.is_admin : undefined;
  return { isAdmin, isLoading };
};

export const getScenarios = () => {
  const [fetchFlag, setFetchFlag] = useState<boolean>(true);
  const { data, isLoading, isError } = useAuthenticatedQuery<scenarioData>(
    ["getScenarios"],
    {
      url: `${fixed}simulations`,
      method: "GET",
      headers: { "Content-Type": "application/json" },
    },
    { enabled: fetchFlag }
  );

  useEffect(() => {
    if (data?.success) {
      setFetchFlag(false);
    }
  }, [data]);

  const fetchedScenarios = data?.success ? data.data.simulations : [];
  return {
    fetchedScenarios,
    isLoading,
    refetch: () => setFetchFlag(!fetchFlag),
  };
};
export const getSharedScenarios = () => {
  const [fetchFlag, setFetchFlag] = useState<boolean>(true);
  const { data, isLoading, isError } = useAuthenticatedQuery<scenarioData>(
    ["getSharedScenarios"],
    {
      url: `${fixed}simulations/sharedsimulations`,
      method: "GET",
      headers: { "Content-Type": "application/json" },
    },
    { enabled: fetchFlag }
  );

  useEffect(() => {
    if (data?.success) {
      setFetchFlag(false);
    }
  }, [data]);
  const isSharedLoading = isLoading;

  const fetchedSharedScenarios = data?.success ? data.data.simulations : [];
  return {
    fetchedSharedScenarios,
    isSharedLoading,
    setSharedFetchFlag: () => setFetchFlag(!fetchFlag),
  };
};
export const GetArpprovedCountries = () => {
  const { data, isLoading, isError } = useAuthenticatedQuery<any>(
    ["approvedCountries"],
    {
      url: `${fixed}users/get_markets`,
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  );

  const isCountryLoading = isLoading;
  const approvedCountries = data?.success ? data.data.markets : [];
  return { approvedCountries, isCountryLoading };
};

export const GetCurrentScenario = (id: string | null) => {
  const { data, isLoading, isError } = useAuthenticatedQuery<any>(
    ["currentScenario", id],
    {
      url: `${fixed}simulations/${id}`,
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  );

  const isSelectedLoading = isLoading;
  const SelectedfiltersData = data?.success ? data.data.data.filter : [];
  const ScenarioMarket = data?.success ? data.data.data.market : "";
  const ScenarioFileName = data?.success ? data.data.data.fileName : null;
  const SavedScenarioName = data?.success ? data.data.name : null;

  return {
    SelectedfiltersData,
    ScenarioMarket,
    isSelectedLoading,
    ScenarioFileName,
    SavedScenarioName,
  };
};

export const GetSimulationNames = () => {
  const { data, isLoading, isError } = useAuthenticatedQuery<any>(
    ["currentScenario"],
    {
      url: `${fixed}get_simulation_names`,
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  );


  const SimulationName = data?.success ? data.data.simulations : [];
  return { SimulationName, isLoading };
};

export const getApprovedUsers = () => {
  const { data, isLoading, isError } = useAuthenticatedQuery<ApprovedUser[]>(
    ["getApprovedUser"],
    {
      url: `${fixed}users/approved`,
      method: "GET",
      headers: { "Content-Type": "application/json" },
    },
  );

  const isUserLoading = isLoading;
  const approvedUSers = data?.success ? data.data : [];
  return { approvedUSers, isUserLoading };
};

export const getWarningBr = () => {
  const { data, isLoading, isError } = useAuthenticatedQuery<DataStructure[]>(
    ["getModelRangesBr"],
    {
      url: `${fixed}/model_ranges_br`,
      method: "GET",
      headers: { "Content-Type": "application/json" },
    },
  );

  const isModelRangeLoading = isLoading;
  const ModelRange = data?.success ? data.data : [];
  return { ModelRange, isModelRangeLoading };
};

export const getWarningSA = () => {
  const { data, isLoading, isError } = useAuthenticatedQuery<DataStructure[]>(
    ["getModelRangesSA"],
    {
      url: `${fixed}/model_ranges`,
      method: "GET",
      headers: { "Content-Type": "application/json" },
    },
  );

  const isModelRangeLoading = isLoading;
  const ModelRange = data?.success ? data.data : [];
  return { ModelRange, isModelRangeLoading };
};

export const getApprovalList = () => {
  const { data, isLoading, isError } =
    useAuthenticatedQuery<approvalResponseData>(["userList"], {
      url: `${fixed}users`,
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });

  const approved = data?.success ? data.data : [];
  return {
    approved,
    isLoading,
  };
};

export const getPendingApproval = () => {
  const { data, isLoading, isError } =
    useAuthenticatedQuery<approvalResponseData>(["pendingUsers"], {
      url: `${fixed}users/pending`,
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });


  const pending = data?.success ? data.data : [];
  return {
    pending,
    isLoading,
  };
};

export const getUser = (id: string) => {
  const navigate = useNavigate();
  const { data, isLoading, isError } =
    useAuthenticatedQuery<approvalResponseData>(["EditUser"], {
      url: `${fixed}user/${id}`,
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
  // useEffect(() => {
  //   if (isError) {
  //     navigate("/404");
  //   }
  // }, [isError, navigate]);
  const user = data?.success ? data.data : [];
  return {
    user,
    isLoading,
  };
};

export const logout = (msalDetails: IMsalContext) => {
  sessionStorage.removeItem(AUTH_TOKEN_KEY);
  msalDetails.instance.logout();
};

const fetchQuery = async (
  endpoint: string,
  data?: unknown,
  method: string = "POST"
) => {
  const options: RequestInit = {
    method,
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (method !== "GET" && method !== "HEAD") {
    options.body = JSON.stringify(data);
  }

  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}${endpoint}`,
      options
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();

    toast.success("Request successful");
    return responseData;
  } catch (error) {
    const errorMessage = (error as Error).message;
    toast.error(`Request failed: ${errorMessage}`);
    throw error;
  }
};

export default fetchQuery;
